.services {
  &__item {
    flex-direction: column;
    position: relative;
  }

  &__item_content {
    background: $_cat;
    color: white;
    flex: 1;
    z-index: 9;
  }

  &__image {
    height: 300px;
    background-size: cover;
    background-position: top;
  }
}