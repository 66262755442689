h1, h2 {
  font-weight: 700;
  line-height: 1.4;
  font-family: 'Optima', serif;
  font-weight: bold;
  color: $color-headings-text;
}

h2 {
  font-size: 1.6em;

  @media(min-width: 900px) {
    font-size: 1.8em;
  }
}

h3 {
  font-size: 1.4em;
  line-height: 1.4;
  font-family: 'Optima', serif;
  font-weight: 500;
}

a {
  color: $color-link-text;
  
  &:hover {
    text-decoration: underline;
    color: $color-link-text-hover;
  }
}

p {
  line-height: 1.4;
}

.text {
  &--large {
    line-height: 1.4;
    font-size: 1em;

    @media(min-width: 1008px) {
      font-size: 1.2em;
    }
  }
}

.mission-statement {
  line-height: 1.6;
  font-size: 1.4em;
  font-weight: 500;

  @media(min-width: 1008px) {
    font-size: 1.8em;
  }
}

.link {
  font-weight: 700;

  &--white {
    color: white;
  }
}