.header {
  z-index: 9;
  box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.4) !important;
  overflow: hidden;

  &__logo {
    max-width: 75px;

    @media(min-width: 900px) {
      max-width: 100px;
    }
  }

  &__notification {
    border-bottom: 1px solid #f0f0f0;

    svg {
      color: #b2b2b2;
    }

    a {
      color: #b2b2b2;
      font-size: 1.2em;

      span {
        font-size: 0.85em;
      }
    }
  }
}