.footer {
  background: $color-footer-background;
  color: $color-footer-text;
  text-align: center;
  box-shadow: 1px 0px 10px -5px rgba(0, 0, 0, 0.4) !important;

  @media(min-width: 900px) {
    text-align: left;
  }

  a {
    color: $color-footer-link;
  }

  &__text {
    vertical-align: text-bottom;
  }

  &__socials {
    font-size: 1.8em;
  }

  &__section_item {
    font-size: 1.2em;
  }

  &--white {
    background: white;
    color: $_cat;

    a {
      color: $_cat
    }
  }
}