$_monkey: #5E6167;
$_cat: #212e50;
$_ant: #3789a1;
$_giraffe: #ffffff;
$_possum: black;
$_dog: #4bbee0;

$color-body-text: $_cat;
$color-body-background: $_giraffe;

$color-footer-background: $_cat;
$color-footer-text: $_giraffe;
$color-footer-link: $_giraffe;

$color-link-text: $_dog;
$color-link-text-hover: $_ant;
$color-link-text-active: $_ant;
$color-link-text-background-hover: $_monkey;

$color-header-notification-background: $_cat;
$color-nav-background: $_giraffe;
$color-nav-background-mobile: $_cat;
$color-nav-logo-background: $_monkey;

$color-facebook: #4267b2;

$color-section-background: $_giraffe;
$color-section-text: $_cat;

$color-button-text: $_giraffe;
$color-button-background: $_dog;
$color-button-background-hover: $_ant;

$color-section-background-dark: $_cat;
$color-section-text-dark: $_giraffe;

$color-headings-text: $_cat;
$color-hero-heading: $_dog;

$color-reviews-background: $_cat;
$color-reviews-text: $_giraffe;

.text--white {
  color: white;
}

.text--orange {
  color: $_ant;
}