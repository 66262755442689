.slice {
  &-top {
    margin-top: 64px;

    @media(min-width: 900px) {
      margin-top: 128px;
    }
  }

  &-bottom {
    margin-bottom: 64px;

    @media(min-width: 900px) {
      margin-bottom: 128px;
    }
  }
}

.home {
  &__paralax_content {
    background: #5f6167;
    padding: 20px;
    width: 50vw;
    height: 100%;
  }

  &__section {

    &_dark {
      background-color: rgba(55, 137, 161, 0.15);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* Safari 3-4, iOS 1-3.2, Android 1.6- */
      -webkit-border-radius: 50%; 

      /* Firefox 1-3.6 */
      -moz-border-radius: 50%; 
      
      /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
      border-radius: 50%; 
    }

    @media(min-width: 900px) {
      height: 100vh;

      &--reversed {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
    }

    &_text {
      background-color: $color-section-background;
      color: $color-section-text;
      position: relative;

      &_content {

        @media(min-width: 900px) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
    
      h3 {
        font-size: 2em;
        font-weight: bold;
      }

      p, a {
        font-size: 1.2em;
      }

      a {
        border-radius: 28px;
      }
    }

    &_image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 500px;
    }
  }
}