.list {

  &-item-icon {
    font-size: 3em !important;
  }

  &-item-text {
    font-size: 1.4em;
    line-height: 1.4;
    font-weight: 600;
    max-width: 160px;

    &--large {
      max-width: 220px;
    }
  }
}