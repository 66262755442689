@import "./utils/reset";
@import "./utils/colours";
@import "./utils/base";
@import "./utils/typography";
@import "./utils/grid";
@import "./utils/parallax";
@import "./utils/fonts";

@import "node_modules/bbc-grandstand/dist/bbc-grandstand";

@import "./components/nav";
@import "./components/hero";
@import "./components/header";
@import "./components/testimonies";
@import "./components/footer";
@import "./components/card";
@import "./components/list";
@import "./components/services";
@import "./components/reviews";
@import "./pages/home";

ul {
  list-style-type: none;
}