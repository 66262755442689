.testimonies {
  li {
    font-size: 1.4em;
  }

  p {
    font-size: 0.9em;
  }

  &__stars {
    color: #b2b2b2;;
  }
}

.gel-wrap-small {
  max-width: 800px !important;
}