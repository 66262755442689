
.hero {
  background: $color-body-text;
  min-height: calc(100vh - 49px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  

  &__left {

    @media(min-width: 1008px) {
      position: absolute;
      background: #fff;
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }

  &__heading {
    margin: 0 auto;
    max-width: 800px;
    color: $color-hero-heading;
  }

  h2 {
    font-family: 'Optima', serif;
    font-size: 32px;
    color: white;
    font-weight: 700;

    @media(min-width: 600px) {
      font-size: 32px;
    }

    @media(min-width: 1008px) {
      font-size: 36px;
      color: $color-hero-heading
    }
  }

  &__list {
    @media(min-width: 1008px) {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      justify-content: space-between;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;

      li {
        max-width: 368px;
        min-width: 368px;
        padding: 0 20px;
        position: relative;
      }
    }
  }

  &__list_item {
    img {
      width: 200px;

      @media(min-width: 900px) {
        width: 250px;
      }
    }

    .button {
      @media(max-width: 1008px) {
        background-color: white;
        color: $color-body-text;
      }
    }

    h4 {
      color: white;
      font-family: 'Optima', serif;
      font-size: 22px;
      letter-spacing: normal;

      @media(min-width: 1008px) {
        color: $color-body-text;
      }
    
      &.white {
        color: white;
      }
    }
  }

  &__versus {
    height: 135px;
    width: 135px;
    background: #fff;
    border-radius: 50%;
    color: $color-body-text;
    font-size: 46px;
    font-family: 'Optima', serif;
    text-align: center;
    margin: 70px auto 70px;
    line-height: 134px;

    @media(min-width: 1008px) {
      margin: 115px auto 70px;
    }
  }
}
