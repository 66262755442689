$gutterSize: 32px;

.square-container {
  @media(min-width: 900px) {
    margin-right: -#{$gutterSize / 2};
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.square {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 100%;

  .content {
    width: 100%;
    height: 100%;
    margin-right: #{$gutterSize / 2};
    margin-bottom: #{$gutterSize / 2};
    padding: 16px;
    position: absolute;
    background-color: #e4e4e4;
    background-size: cover;
    background-position: center;

    @media(min-width: 900px) {
      width: calc(100% - #{$gutterSize});
      height: calc(100% - #{$gutterSize});
    }

    .content-text {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      font-weight: 600;
    }
  }

  @media(min-width: 56.25em) {
    width: calc(100% / 2);
    padding-bottom: calc(100% / 2);
  }

  @media(min-width: 63em) {
    width: calc(100% / 3);
    padding-bottom: calc(100% / 3);
  }

  @media(min-width: 80em) {
    width: calc(100% / 4);
    padding-bottom: calc(100% / 4);
  }
}