body {
  font-family: 'Optima', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-body-background;
  color: $color-body-text;
}

body * {
  font-family: 'Optima', sans-serif;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
  }
}

.content {
  // display: flex;
  // flex-direction: column;
  // height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.icon {
  color: #fd5100;

  &--large {
    font-size: 6em !important;
  }
}

.group:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.group {
  display: inline-block;
}

.icon-float {
  @media(min-width: 900px) {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.round-image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: #e4e4e4;
  background-size: cover;
  background-position: center;
}

.product {
  min-height: 230px;
}

.error {
  font-size: 0.9em;
  color: red;
}

.input {
  font-size: 1em;
}

.button {
  font-family: 'Optima', sans-serif;
  font-size: 1em;
  background-color: $color-button-background;
  border: none;
  color: $color-button-text;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  border-radius: 28px;
  font-size: 15px !important;
  outline: none;

  &--large {
    font-size: 1.1em !important;
  }

  &--white {
    color: $color-button-background;
    background-color: $color-button-text;
  }

  &--wide {
    width: 100%;

    @media(min-width: 600px) {
      min-width: 120px;
      width: auto;
    }
  }

  &:hover, &:focus {
    background-color: $color-button-background-hover;
    color: $color-button-text;
    text-decoration: none !important;
  }

  &:disabled {
    color: #999;
    background-color: white;
    border: 1px solid #999;
    cursor: not-allowed;
  }
}

.underline {
  height: 2px;
  width: 24px;
  background: #fd5100;
  display: inline-block;
}

.spacing-slice {
  margin-top: 32px !important;

  @media(min-width: 900px) {
    margin-top: 64px !important;
  }

  &--bottom {
    margin-bottom: 32px !important;

    @media(min-width: 900px) {
      margin-bottom: 64px !important;
    }
  }

  &--large {
    margin-top: 32px !important;

    @media(min-width: 900px) {
      margin-top: 96px !important;
    }

    &--bottom {
      margin-bottom: 32px !important;

      @media(min-width: 900px) {
        margin-bottom: 96px !important;
      }
    }
  }
}

.padding-slice {
  padding-top: 32px;

  @media(min-width: 900px) {
    padding-top: 64px;
  }

  &--bottom {
    padding-bottom: 32px;

    @media(min-width: 900px) {
      padding-bottom: 64px;
    }
  }

  &--large {
    padding-top: 32px;

    @media(min-width: 900px) {
      padding-top: 96px;
    }

    &--bottom {
      padding-bottom: 32px;

      @media(min-width: 900px) {
        padding-bottom: 96px;
      }
    }
  }
}

.textcontent {
  max-width: 900px;
  margin: 0 auto;
}

.mini-wrap {
  max-width: 800px;
  margin: 0 auto;
}

.image {
  &--rounded {
    /* Safari 3-4, iOS 1-3.2, Android 1.6- */
    -webkit-border-radius: 50%; 

    /* Firefox 1-3.6 */
    -moz-border-radius: 50%; 
    
    /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
    border-radius: 50%; 
  }

  &--coloured-border {
    border: 12px solid;
    
    &-white {
      border-color: white;
    }

    @media(min-width: 1008px) {
      border: 24px solid;
      
      &-white {
        border-color: white;
      }
    }
  }

  &--white-bg {
    background-color: white;
  }
}


.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered\@l {
  @media(min-width: 900px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.section {
  &--dark {
    background-color: $color-section-background-dark;

    p, h2 {
      color: $color-section-text-dark;
    }
  }
}

.not-found {
  h1 {
    font-size: 6em;
  }
}