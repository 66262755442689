.nav {
  list-style-type: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  &__mini_button {
    font-size: 1.6em;
    color: $color-body-text;
  }

  &__item {
    font-size: 1.2em !important;

    span {
      display: none;

      @media(min-width: 900px) {
        display: inline;
      }
    }
  }

  &__button {
    font-size: 1.8em;
    background: transparent;
  }

  &__logo {
    position: absolute;
    left: 50%;
    margin-left: -100px;

    @media(min-width: 900px) {
      margin-left: -150px;
    }

    img {
      max-width: 200px;

      @media(min-width: 900px) {
        max-width: 300px;
      }
    }

    &--foot {
      top: -2px;
      margin-left: -100px;

      @media(min-width: 900px) {
        top: -12px;
        margin-left: -130px;
      }

      img {
        max-width: 200px;

        @media(min-width: 900px) {
          max-width: 260px;
        }
      }
    }

    &--injury {
      top: -16px;
      margin-left: -100px;

      @media(min-width: 900px) {
        top: -32px;
        margin-left: -130px;
      }

      img {
        max-width: 200px;

        @media(min-width: 900px) {
          max-width: 260px;
        }
      }
    }
  }

  &__dropdown {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.4) !important;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &__content {
    border-radius: 5px;
    background-color: white;
    width: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__dropdown_button {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 2em !important;
    background: transparent;

    &:hover, &:focus {
      background-color: transparent;
      color: $_ant;
    }
  }
}